<template>
  <div id="login">
    <p class="login-text">
      Permit Book is revolutionizing the way drivers access their permits and
      documents.
    </p>
    <label v-bind:class="emailAddress ? 'visible' : ''">Email</label>
    <input
      type="email"
      v-model="emailAddress"
      placeholder="Email"
      class="email"
    />
    <label v-bind:class="password ? 'visible' : ''">Password</label>
    <input
      type="password"
      v-model="password"
      placeholder="Password"
      class="password"
    />
    <button
      id="login-submit"
      type="submit"
      class="button button-primary"
      :disabled="!isComplete"
      v-on:click="logIn()"
    >
      sign in
    </button>
    <div id="login-final-row">
      <div id="error-box" v-if="error && error.length">
        <img
          src="../assets/warning-triangle.svg"
          title="error"
          alt="error triangle"
        />
        <p id="error-message" class="login-text">{{ this.error }}</p>
      </div>
      <p id="forgot-password" class="login-text">
        <router-link to="/forgot">Forgot Password?</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  computed: {
    isComplete() {
      return this.emailAddress && this.password
    }
  },
  data() {
    return {
      emailAddress: '',
      password: '',
      error: ''
    }
  },
  created() {
    const isSignedIn = this.$session.isSignedIn()

    if (isSignedIn) {
      this.$router.replace('/admin/user')
    } else {
      this.$session.logOut() // Make sure everything is cleared
    }
  },
  methods: {
    logIn: async function () {
      this.error = ''
      this.$events.$emit('showLoading')
      try {
        if (!this.emailAddress || !this.password) {
          throw new Error('Please enter your email address and password.')
        }
        await this.$session.logIn(this.emailAddress, this.password)
        this.$events.$emit('hideLoading')
      } catch (err) {
        this.$events.$emit('hideLoading')
        this.error = err.message
        await this.$session.logOut(true)
      }
    }
  }
}
</script>

<style lang="scss">
#login {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 24px;

  .login-text,
  .login-text a {
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.7);
    font-family: Poppins;
    font-size: 14px;
    text-align: center;
    color: $white;
    max-width: 444px;
  }

  label {
    text-shadow: 1px 1px 0 $black-trans;
    font-family: Barlow;
    font-size: 0.75em;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: $white;
    text-transform: uppercase;
    align-self: flex-start;
    margin-top: 20px;
    margin-left: 8px;
    opacity: 0;
  }

  label.visible {
    opacity: 1;
  }

  input,
  textarea {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $white;
    line-height: 20px;
    text-shadow: 1px 1px 0 $black-trans;
    font-size: 16px;
    font-weight: 500;
    color: $white;
    margin: 20px 0px;
    width: 458px;
    outline: none;
  }

  input:focus {
    border-bottom: 1px solid $white;
  }

  ::placeholder {
    color: $white;
  }

  #login-final-row {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 20px;
    width: 100%;
    padding: 1em 0.5em;

    #forgot-password {
      grid-column: 2;
      justify-self: end;
      text-decoration: underline;
    }

    #error-box {
      grid-column: 1;
      display: flex;
      justify-self: start;
      align-items: center;

      #error-message {
        text-align: center;
        color: $vermilion;
        font-style: italic;
        margin-left: 10px;
      }
    }
  }

  #login-submit {
    width: 458px;
    font-weight: 600;
    font-size: 19px;
    align-self: flex-start;
  }

  @media (orientation: landscape) and (max-width: $landscape-width) {
  }

  @media (orientation: portrait) and (max-width: $mobile-width) {
    .text {
      width: 90%;
    }

    .buttons {
      align-self: center;
    }
  }
}
</style>
